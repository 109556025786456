import { Button, Caption1, Card, CardHeader, CardPreview, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Label } from "@fluentui/react-components";
import { Dismiss24Filled, DismissRegular } from "@fluentui/react-icons";
import React from "react";
import { processServerError } from "../../utils/helpers/error.helper";
import { AnalysisStatus, FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { DialogFilePicker } from "../dialog-file-picker/dialog-file-picker.component";
import { dwsimExtensions, flowsheetExtensions } from "../../global.variables";
import { FileDisplayName } from "../file-display-name/file-display-name.component";
import { UploadFileToLocation } from "./upload-file-to-location.component";
import { ExampleFilePickerDialog } from "../example-file-picker-dialog/example-file-picker-dialog.component";
import { HubConnection } from "@microsoft/signalr";
import { UseExampleFileButton } from "./use-example-file-button.component";
import { AnalysisStatusIcon } from "../../files/analysis-status/analysis-status-icon.component";
import { useNavigate } from "react-router-dom";
import FilterPicker from "../filter-picker/filter-picker.component";

type CreateCopilotExperimentWizardModalProps = {
    isOpened?: boolean;
    hubConnection: HubConnection;
    title: string;
    application: WizardApplication;
    onClose?: () => void;
}
export enum WizardApplication {
    Copilot,
    DoE,
    MSS,
    OptimumTest,
    WebForm
}

export const CreateCopilotExperimentWizardModal: React.FC<CreateCopilotExperimentWizardModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showFilePicker, setShowFilePicker] = React.useState<boolean>(false);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [analysisStatus, setAnalysisStatus] = React.useState<AnalysisStatus | undefined>(null);
    const [selectedFilterId, setSelectedFilterId] = React.useState<number>();
    const navigate = useNavigate();

    React.useState<boolean>(false);
    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);


    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const onContinueClick = () => {
        onClose();
        switch (props.application) {
            case WizardApplication.Copilot:
                navigate(`/files/${selectedFile!.uniqueIdentifier!}/copilot/edit`);
                break;
            case WizardApplication.DoE:
                navigate(`/files/${selectedFile.uniqueIdentifier}/doe/edit?filterId=${selectedFilterId}`);
                break;
            case WizardApplication.MSS:
                navigate(`/files/${selectedFile.uniqueIdentifier}/sensitivity-studies/edit?filterId=${selectedFilterId}`);
                break;
            case WizardApplication.OptimumTest:
                navigate(`/files/${selectedFile.uniqueIdentifier}/optimum-tester/edit?filterId=${selectedFilterId}`);
                break;
            case WizardApplication.WebForm:
                navigate(`/files/${selectedFile!.uniqueIdentifier!}/filters/edit${selectedFilterId ? `/${selectedFilterId}` : ""}`);
                break;

            default:
                break;
        }

    }

    const IsAllSelected = () => {
        switch (props.application) {
            case WizardApplication.Copilot:
                return !!selectedFile && analysisStatus === AnalysisStatus.Success;
            case WizardApplication.DoE:
                return !!selectedFile && analysisStatus === AnalysisStatus.Success && !!selectedFilterId;
            case WizardApplication.MSS:
                return !!selectedFile && analysisStatus === AnalysisStatus.Success && !!selectedFilterId;;
            case WizardApplication.OptimumTest:
                return !!selectedFile && analysisStatus === AnalysisStatus.Success && !!selectedFilterId;;
            case WizardApplication.WebForm:
                return !!selectedFile && analysisStatus === AnalysisStatus.Success;
            default:
                return true;

        }
    }

    const doesRequireFlowsheetAnalysis = () => {
        switch (props.application) {
            case WizardApplication.Copilot:
                return true;
            case WizardApplication.DoE:
                return true;
            case WizardApplication.MSS:
                return true;
            case WizardApplication.OptimumTest:
                return true;
            case WizardApplication.WebForm:
                return true;
            default:
                return false;
        }
    }
    const doesRequireFilter = () => {
        switch (props.application) {
            case WizardApplication.Copilot:
                return false;
            case WizardApplication.DoE:
                return true;
            case WizardApplication.MSS:
                return true;
            case WizardApplication.OptimumTest:
                return true;
            // Does not require but can select   
            case WizardApplication.WebForm:
                return true;
            default:
                return false;
        }
    }

    const allSelected = IsAllSelected();
    const requiresFlowsheetAnalysis = doesRequireFlowsheetAnalysis();
    const analysisFinished = requiresFlowsheetAnalysis ? analysisStatus === AnalysisStatus.Success : true;
    const requiresFilter = doesRequireFilter();

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "95vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}
                    style={{ display: "flex", alignItems: "center", gap: "20px" }}>

                    <span>{props.title}</span>

                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Card>
                            <CardHeader
                                header={<span><b>Step 1:</b> Select Flowsheet</span>}
                                description={<Caption1>Select your flowsheet, upload a flowsheet or use our examples.</Caption1>}
                            />
                            <CardPreview>
                                <div style={{ margin: "10px" }}>
                                    {selectedFile && <div style={{ display: "flex" }}>
                                        <div className="input-form" style={{ flex: "1" }}>
                                            <div className="input-form-item">
                                                <Label className="input-form-label">
                                                    Flowsheet:
                                                </Label>
                                                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}><FileDisplayName
                                                    fileUniqueIdentifier={selectedFile?.uniqueIdentifier}
                                                    fileVersionNumber={selectedFile?.currentVersionNumber}
                                                /></p>
                                                <Button
                                                    icon={<DismissRegular />}
                                                    appearance="transparent"
                                                    onClick={() => { setSelectedFile(undefined); setSelectedFilterId(undefined); }}
                                                ></Button>

                                            </div>
                                        </div>
                                    </div>}
                                    {!selectedFile && <div style={{ display: "flex", gap: "10px" }}>
                                        <Button appearance="primary" onClick={() => { setShowFilePicker(true); }}>Select flowsheet</Button>
                                        <UploadFileToLocation
                                            filterFileTypes={dwsimExtensions.map(x => `.${x}`)}
                                            title="Upload flowsheet"
                                            onFileUploaded={(file: FileModel) => { setSelectedFile(file); }} />

                                        <UseExampleFileButton
                                            hubConnection={props.hubConnection}
                                            onFileCopiedToDashboard={(file) => { setSelectedFile(file) }}
                                        />


                                    </div>}

                                </div>

                            </CardPreview>
                        </Card>
                        {requiresFlowsheetAnalysis && selectedFile && <Card>
                            <CardHeader
                                header={<span><b>Step 2:</b> Flowsheet Analysis</span>}
                                description={<Caption1>Flowsheet needs to be analyzed to continue your work.</Caption1>}
                            />
                            <CardPreview>
                                <div style={{ "display": "flex", flexDirection: "column", margin: "10px" }}>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>Analysis status:</span>
                                        <AnalysisStatusIcon
                                            hubConnection={props.hubConnection}
                                            fileUniqueIdentifier={selectedFile.uniqueIdentifier!}
                                            fileVersionNumber={selectedFile.currentVersionNumber!}
                                            filename={selectedFile.name!}
                                            onAnalysisStatusChange={(status) => { setAnalysisStatus(status); }} />
                                    </div>
                                    {analysisStatus === undefined && <span>Flowsheet needs to be submitted for analysis. Please click on Analysis status icon above.</span>}
                                    {analysisStatus == AnalysisStatus.Submitted && <span>Flowsheet was submitted for analysis. Please wait...</span>}
                                    {analysisStatus == AnalysisStatus.Fail && <span>An error occurred while analyzing flowsheet.</span>}
                                </div>


                            </CardPreview>
                        </Card>}

                        {analysisFinished && requiresFilter && selectedFile && <Card>
                            <CardHeader
                                header={<span><b>Step {requiresFlowsheetAnalysis ? "3" : "2"}:</b> Select filter {props.application == WizardApplication.WebForm ? "or Continue" : ""}</span>}
                                description={<Caption1>Select or create filter.</Caption1>}
                            />
                            <CardPreview>
                                <div style={{ "display": "flex", flexDirection: "column", margin: "10px" }}>
                                    <div className="input-form" style={{ flex: "1" }}>
                                        <div className="input-form-item">
                                            <Label className="input-form-label">
                                                Filter:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                                                <FilterPicker
                                                    showCreateOption
                                                    style={{ maxWidth: "300px" }}
                                                    filterId={selectedFilterId}
                                                    fileId={selectedFile.id}
                                                    fileUniqueId={selectedFile.uniqueIdentifier}
                                                    hubConnection={props.hubConnection}
                                                    onChange={(filterId) => { setSelectedFilterId(filterId); }} />
                                            </p>


                                        </div>
                                    </div>
                                </div>
                            </CardPreview>
                        </Card>}
                    </div>

                    {showFilePicker &&
                        <DialogFilePicker
                            isOpened={showFilePicker}
                            filterFileTypes={flowsheetExtensions}
                            onClose={() => { setShowFilePicker(false); }}
                            onFileSelected={(file) => { setSelectedFile(file); setShowFilePicker(false); }} />}




                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" disabled={!allSelected} onClick={onContinueClick} type="button">Continue</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}
