import React from "react";
import { GLOBAL_STYLES } from "../../styles";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Spinner, Input, Field, MessageBar, MessageBarBody } from "@fluentui/react-components";

import { S365Button } from "../../components/s365-button/s365-button.component";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CheckmarkRegular, CheckRegular, Dismiss24Filled, ErrorCircleRegular } from "@fluentui/react-icons";
import { getResetPasswordClient } from "../../services/dashboard.service";
import { getErrorMessagesFromResponse } from "../../utils/helpers/error.helper";
import { ResetPasswordRequestModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

type ResetPasswordStep1DialogProps = {
    isOpened?: boolean;
    onClose: () => void;
}

type ResetPasswordModel = {
    email: string;
    hCaptchaVerified: boolean;
}

const formSchema = Yup.object().shape<
    Record<keyof ResetPasswordModel, Yup.AnySchema>
>({
    email: Yup.string()
        .required("Business email or Simulate 365 username is required.")
        .email("Business email or Simulate 365 username is not in correct format."),
    hCaptchaVerified: Yup.boolean()
        .required("Captcha is required.")
        .oneOf([true], "Captcha is required."),
});


export const ResetPasswordStep1Dialog: React.FC<ResetPasswordStep1DialogProps> = (props) => {

    const [errorMessages, setErrorMessages] = React.useState<string[]>();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);


    const {
        register,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        setValue,
        formState: { errors, isSubmitted },
        reset,
    } = useForm<ResetPasswordModel>({ resolver: yupResolver(formSchema) as any });


    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const onSubmit = async (data: ResetPasswordModel) => {
        try {
            setIsLoading(true);
            const client = getResetPasswordClient();
            const model = new ResetPasswordRequestModel({
                emailOrUsername: data.email
            });
            await client.resetPassword(model);
            setIsSuccess(true);
        } catch (error) {
            console.log("An error occurred while reseting password.", error);
            const errors = getErrorMessagesFromResponse(
                error,
                undefined,
                "An error occurred while reseting password."
            );
            setErrorMessages(errors);
        } finally {
            setIsLoading(false);
        }
    }


    return <Dialog
        open={open}
        modalType="alert"
        inertTrapFocus
        onOpenChange={(event, data) => {
            setOpen(data.open);
            if (!data.open) {
                onModalClose();
            }
        }}
    >
        <DialogSurface style={{ maxWidth: "650px" }}>
            <form className="register-form-modal" noValidate onSubmit={handleSubmit(onSubmit)}>

                <DialogBody style={{ maxWidth: "inherit" }}>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />} />
                    {!isLoading && !isSuccess && <DialogContent
                        className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                        style={{ paddingLeft: "100px", paddingRight: "100px" }}
                    >
                        {!isLoading &&
                            !!errorMessages &&
                            errorMessages.length > 0 && (
                                <MessageBar icon={<ErrorCircleRegular />} intent="error" layout="multiline"
                                    style={{ marginBottom: "20px", alignItems: "center", width: "100%" }}>
                                    <MessageBarBody>
                                        {errorMessages.map((message) => {
                                            return <span>
                                                {message}
                                            </span>;
                                        })}
                                    </MessageBarBody>
                                </MessageBar>
                            )}

                        <h2 style={{ marginTop: 0, marginBottom: "var(--spacingVerticalXXXL)", textAlign: 'center' }}>Forgot password</h2> 
                        <div className="input-form">
                            <div style={{ display: "flex", gap: "var(--spacingHorizontalL)", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                                    <Field
                                        label="Business email or Simulate 365 username"
                                        required
                                        className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                                        validationState={errors.email ? "error" : "none"}
                                        validationMessage={
                                            errors.email ? errors.email.message : undefined
                                        }
                                    >
                                        <Input
                                            id="email"
                                            type="email"
                                            className="input-field"
                                            {...register("email")}
                                        //   contentBefore={<MailRegular />}
                                        />
                                    </Field>
                                </div>

                                <div style={{ width: "100%", display: "flex", flex: 1, justifyContent: "center" }}>
                                    <Field
                                        validationState={
                                            errors.hCaptchaVerified ? "error" : "none"
                                        }
                                        validationMessage={
                                            errors.hCaptchaVerified
                                                ? errors.hCaptchaVerified.message
                                                : undefined
                                        }
                                    >
                                        <HCaptcha
                                            sitekey="ca28c15d-d3b1-4075-97c8-7dbcfea0262e"
                                            onVerify={(token) => {
                                                // console.log("onVerify hcaptcha", token);
                                                if (!!token)
                                                    setValue("hCaptchaVerified", true, {
                                                        shouldDirty: true,
                                                        shouldTouch: true,
                                                        shouldValidate: true,
                                                    });
                                            }}
                                        />
                                    </Field>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <S365Button appearance="primary" type="submit" size="large">Send me a password reset link</S365Button>
                                </div>

                            </div>
                        </div>

                    </DialogContent>}
                    {isLoading && <DialogContent
                        className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                        style={{ paddingLeft: "100px", paddingRight: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Spinner label={"Reseting your password, please wait..."} />
                        </div>
                    </DialogContent>}

                    {!isLoading && isSuccess && <DialogContent
                        className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <MessageBar icon={<CheckmarkRegular />} intent="success" layout="multiline" style={{ marginBottom: "20px", alignItems: "center", width: "100%" }}>
                                <MessageBarBody>
                                    <span>
                                        Success! If there's an account associated with that email or username, we've sent a link to reset your password.</span>
                                </MessageBarBody>
                            </MessageBar>
                            <S365Button appearance="primary" type="button" size="large" onClick={onModalClose}>Done</S365Button>
                        </div>
                    </DialogContent>}
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>
}