import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import * as React from "react";
import { createLoginRequest } from "../../auth/authConfig";
import { WelcomePage } from "../welcome/welcome.page";
import { HubConnection } from "@microsoft/signalr";
import { Button, Spinner } from "@fluentui/react-components";
import sidebarLogo from "../../assets/sidebar-logo.png";
import { useSearchParams } from "react-router-dom";
import { useLoading } from "../../utils/loading-indicator.component";
import {
  getErrorMessagesFromResponse,
  processServerError,
} from "../../utils/helpers/error.helper";
import { getUsersClient } from "../../services/dashboard.service";
import { RegisterModal } from "./register-modal";
import { S365Button } from "../../components/s365-button/s365-button.component";
import { EmailConfirmationDialog } from "./email-confirmation-dialog";
import { ResetPasswordStep1Dialog } from "./reset-password-step1-dialog";
type HomePageProps = {
  hubConnection: HubConnection;
};

export const LoginRegisterOverlay: React.FC<HomePageProps> = (props) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [searchParams, setSearchParams] = useSearchParams();

  const [confirmationToken, setConfirmationToken] = React.useState<string>();
  const [showEmailConfirmDialog, setShowEmailConfirmDialog] = React.useState<boolean>(false);

  const [showRegisterModal, setShowRegisterModal] = React.useState<boolean>(false);
  const [showResetPasswordStep1Modal, setShowResetPasswordStep1Modal] = React.useState<boolean>(false);
  const [isLoading, loadingService] = useLoading();

  React.useEffect(() => {
    const confToken = searchParams.get("confirmationToken");
    setConfirmationToken(confToken);
    setShowEmailConfirmDialog(true);
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated) {
      checkIfUserIsLoggedIn();
    }
  }, []);

  const checkIfUserIsLoggedIn = async () => {
    try {
      const account = instance.getAllAccounts()[0];
      console.log("checkIfUserIsLoggedIn instance:", instance);
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: account,
      };

      const userToken = await instance.ssoSilent(createLoginRequest());
      console.log("userTokenResp", userToken);
    } catch (error) {
      console.log("checkIfUserIsLoggedIn error:", error);
    }
  };



  const onLoginClick = () => {
    instance.loginRedirect(createLoginRequest());
  };

  const onRegisterClick = () => {
    setShowRegisterModal(true);
  };

  const onResetPasswordStep1Click = (ev) => {
    ev.preventDefault();
    setShowResetPasswordStep1Modal(true);
  }

  return (
    <>
      <div
        style={{
          zIndex: "1000",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "grey",
          opacity: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>

      {!confirmationToken && (
        <div
          style={{
            zIndex: "1001",
            position: "fixed",
            top: 0,
            left: "260px",
            right: 0,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              padding: "4px",
              opacity: 1,
              display: "flex",
              flexDirection: "row",
              justifyItems: "center",
              gap: "15px",
              zIndex: "99991",
            }}
          >
            
            <Button
              size="large"
              appearance="primary"
              style={{
                color: "white",
                width: "200px",
                borderRadius: "var(--borderRadiusMedium)",
                border:
                  "var(--strokeWidthThin) solid var(--colorNeutralStroke1)",
              }}
              onClick={onRegisterClick}
            >
              Sign up
            </Button>
            <S365Button
              size="large"
              appearance="secondary"
              style={{
                width: "200px",
                color: "white",
                backgroundColor: "#3EB489",
              }}
              onClick={onLoginClick}
            >
              Sign in
            </S365Button>
            <Button
              as="a"
              style={{
                width: "200px",
                fontSize: 'var(--fontSizeBase400)'
              }}
              onClick={onResetPasswordStep1Click}
            >
              Forgot password
            </Button>

          </div>
        </div>
      )}
      <WelcomePage hubConnection={props.hubConnection} />
      {showRegisterModal && (
        <RegisterModal
          isOpened={showRegisterModal}
          onClose={() => {
            setShowRegisterModal(false);
          }}
        />
      )}
      {showResetPasswordStep1Modal && <ResetPasswordStep1Dialog
        isOpened={showResetPasswordStep1Modal}
        onClose={() => { setShowResetPasswordStep1Modal(false); }}
      />}
    </>
  );
};

export default LoginRegisterOverlay;
