import React from "react";
import { GLOBAL_STYLES } from "../../styles";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Spinner, Input, Field, MessageBar, MessageBarBody } from "@fluentui/react-components";

import { S365Button } from "../../components/s365-button/s365-button.component";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CheckmarkRegular, CheckRegular, CopyRegular, Dismiss24Filled, ErrorCircleRegular } from "@fluentui/react-icons";
import { getResetPasswordClient } from "../../services/dashboard.service";
import { getErrorMessagesFromResponse } from "../../utils/helpers/error.helper";
import { ResetPasswordPostModel, ResetPasswordRequestModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { createLoginRequest } from "../../auth/authConfig";
import { useMsal } from "@azure/msal-react";
import { getCurrentWebUrl } from "../../App";
import { toast } from "react-toastify";

type ResetPasswordStep2DialogProps = {
    isOpened?: boolean;
    resetPasswordToken: string;
    onClose: () => void;
}

type ResetPasswordModel = {
    password: string;
    confirmPassword: string;
}

const formSchema = Yup.object().shape<
    Record<keyof ResetPasswordModel, Yup.AnySchema>
>({

    password: Yup.string()
        .required("New password is required.")
        .min(8, "Password must be at least 8 characters long.") // Minimum length
        .matches(/[a-z]/, "Password must contain at least one lowercase letter.") // At least one lowercase letter
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter.") // At least one uppercase letter
        .matches(/\d/, "Password must contain at least one number.") // At least one number
        .matches(
            /[@$!%*?&]/,
            "Password must contain at least one special character."
        ),
    confirmPassword: Yup.string().required("Confirm new password is required.").oneOf([Yup.ref('password')], 'Passwords must match.')
});


export const ResetPasswordStep2Dialog: React.FC<ResetPasswordStep2DialogProps> = (props) => {

    const [errorMessages, setErrorMessages] = React.useState<string[]>();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [userPrincipalName, setUserPrincipalName] = React.useState<string>();
    const { instance } = useMsal();

    const {
        register,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        setValue,
        formState: { errors, isSubmitted },
        reset,
    } = useForm<ResetPasswordModel>({ resolver: yupResolver(formSchema) as any });


    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const onSubmit = async (data: ResetPasswordModel) => {
        try {
            setIsLoading(true);
            const client = getResetPasswordClient();
            const model = new ResetPasswordPostModel({
                newPassword: data.password,
                resetPasswordToken: props.resetPasswordToken
            });
            let response = await client.changePassword(model);
            setIsSuccess(true);
            setUserPrincipalName(response?.userPrincipalName);
        } catch (error) {
            console.log("An error occurred while changing password.", error);
            const errors = getErrorMessagesFromResponse(
                error,
                undefined,
                "An error occurred while changing password."
            );
            setErrorMessages(errors);
        } finally {
            setIsLoading(false);
        }
    }

    const onLoginClick = () => {
        instance.loginRedirect(createLoginRequest(getCurrentWebUrl()));
    };

    const onCopyUsernameClick = (ev) => {
        ev.preventDefault();
        navigator.clipboard.writeText(userPrincipalName);
        toast.success(`Username copied to clipboard.`);
    }

    return <Dialog
        open={open}
        modalType="alert"
        inertTrapFocus
        onOpenChange={(event, data) => {
            setOpen(data.open);
            if (!data.open) {
                onModalClose();
            }
        }}
    >
        <DialogSurface style={{ maxWidth: "650px" }}>
            <form className="register-form-modal" noValidate onSubmit={handleSubmit(onSubmit)}>

                <DialogBody style={{ maxWidth: "inherit" }}>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />} />
                    {!isLoading && !isSuccess && <DialogContent
                        className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                        style={{ paddingLeft: "100px", paddingRight: "100px" }}
                    >
                        {!isLoading &&
                            !!errorMessages &&
                            errorMessages.length > 0 && (
                                <MessageBar icon={<ErrorCircleRegular />} intent="error" layout="multiline"
                                    style={{ marginBottom: "20px", alignItems: "center", width: "100%" }}>
                                    <MessageBarBody>
                                        {errorMessages.map((message) => {
                                            return <span>
                                                {message}
                                            </span>;
                                        })}
                                    </MessageBarBody>
                                </MessageBar>
                            )}

                        <h2 style={{ marginTop: 0, marginBottom: "var(--spacingVerticalXXXL)", textAlign: 'center' }}>Create new password</h2>
                        <div className="input-form">
                            <div style={{ display: "flex", gap: "var(--spacingHorizontalL)", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                                    <Field
                                        label="New password"
                                        required
                                        className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                                        validationState={errors.password ? "error" : "none"}
                                        validationMessage={
                                            errors.password ? errors.password.message : undefined
                                        }
                                    >
                                        <Input
                                            id="password"
                                            type="password"
                                            className="input-field"
                                            {...register("password")}
                                        //   contentBefore={<MailRegular />}
                                        />
                                    </Field>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                                    <Field
                                        label="Confirm new password"
                                        required
                                        className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                                        validationState={errors.confirmPassword ? "error" : "none"}
                                        validationMessage={
                                            errors.confirmPassword ? errors.confirmPassword.message : undefined
                                        }
                                    >
                                        <Input
                                            id="confirmPassword"
                                            type="password"
                                            className="input-field"
                                            {...register("confirmPassword")}
                                        //   contentBefore={<MailRegular />}
                                        />
                                    </Field>
                                </div>

                                <div style={{ flex: 1 }}>
                                    <S365Button appearance="primary" type="submit" size="large">Create new password</S365Button>
                                </div>

                            </div>
                        </div>

                    </DialogContent>}
                    {isLoading && <DialogContent
                        className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                        style={{ paddingLeft: "100px", paddingRight: "100px" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Spinner label={"Reseting your password, please wait..."} />
                        </div>
                    </DialogContent>}

                    {!isLoading && isSuccess && <DialogContent
                        className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                        style={{ paddingLeft: "100px", paddingRight: "100px", paddingTop: 0 }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <MessageBar icon={<CheckmarkRegular />} intent="success" layout="multiline" style={{ marginBottom: "20px", alignItems: "center", width: "100%" }}>
                                <MessageBarBody>
                                    <span>
                                        Your new password has been successfully created.</span>
                                </MessageBarBody>
                            </MessageBar>

                            {userPrincipalName && <>
                                <span style={{ marginBottom: "var(--spacingVerticalXL)", textAlign: 'center' }}>
                                    This is your Simulate 365 username.<br />
                                    <b>Sign in with your username</b>.
                                </span>
                                <Input
                                    size="large"
                                    style={{ width: "100%", marginBottom: "var(--spacingVerticalXL)" }}
                                    input={{ style: { textAlign: "center", fontWeight: "bold" } }}
                                    value={userPrincipalName} readOnly
                                    contentAfter={<Button appearance="transparent" icon={<CopyRegular onClick={onCopyUsernameClick} />}></Button>}
                                />
                            </>}

                            <S365Button
                                size="large"
                                appearance="secondary"
                                style={{ width: "fit-content" }}
                                onClick={onLoginClick}
                            >
                                Continue to sign in
                            </S365Button>
                        </div>
                    </DialogContent>}
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>
}