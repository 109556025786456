import { Button } from "@fluentui/react-components";
import React from "react";
import { FileTableItem } from "../../files/models/file-table.models";
import { dwsimExtensions } from "../../global.variables";
import { ExampleFilePickerDialog } from "../example-file-picker-dialog/example-file-picker-dialog.component";
import { HubConnection } from "@microsoft/signalr";
import { processServerError } from "../../utils/helpers/error.helper";
import { CopyFilePostModel, ErrorResponseModel, FileModel, ValidateFileNamesPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ConflictCopyResponse, CopyFileConflictModal } from "../../files/copy-file-dialog/copy-conflict-dialog.component";
import { useLoading } from "../../utils/loading-indicator.component";
import { getDirectoriesClient, getFilesClient } from "../../services/dashboard.service";
import { getFileExtension, getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { SaveFileModal, SaveFileModel } from "../save-file-modal/save-file-modal.component";
type UseExampleFileButtonProps = {
    hubConnection: HubConnection;
    onFileCopiedToDashboard: (file: FileModel) => void;
}

export const UseExampleFileButton: React.FC<UseExampleFileButtonProps> = (props) => {
    const [showExamplePickerModal, setShowExamplePickerModal] = React.useState<boolean>(false);
    const [selectedExampleFile, setSelectedExampleFile] = React.useState<FileTableItem>();
    const [showDestinationPicker, setShowDestinationPicker] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();



    const onCopyClick = async (data: SaveFileModel) => {
        try {
            const messageId = loadingService.showMessage(`Copying...`);

            try {
                const client = getFilesClient();
                const filename = `${data.name}.${data.extension}`;
                const model = new CopyFilePostModel({
                    name: filename,
                    sourceUniqueIdentifier: selectedExampleFile.uniqueIdentifier,
                    destinationUniqueIdentifier: data?.parentDirectoryUniqueId,
                    fileType: selectedExampleFile.type,
                    conflictAction: data?.conflictAction
                });

                const copiedFile = await client.copyFileOrFolder(model);
                setSelectedExampleFile(undefined);
                props.onFileCopiedToDashboard(copiedFile);
            } catch (error) {
                throw error;
            } finally {
                loadingService.hideMessage(messageId);
            }

        } catch (error) {


            processServerError(error, undefined, `An error occurred while trying to copy file to your dashboard.`);
        }
    }


    const selectedFileExtension = !!selectedExampleFile ? getFileExtension(selectedExampleFile.name) : "dwxmz";
    return <div>
        <Button appearance="primary" onClick={() => { setShowExamplePickerModal(true); }}>Use our example</Button>
        {showExamplePickerModal &&
            <ExampleFilePickerDialog
                isOpened={showExamplePickerModal}
                hubConnection={props.hubConnection}
                filterFileTypes={dwsimExtensions}
                onFileSelected={(file) => { setSelectedExampleFile(file); setShowExamplePickerModal(false); setShowDestinationPicker(true); }}
                onClose={() => { setShowExamplePickerModal(false); }} />}
        {showDestinationPicker && selectedExampleFile &&
            <SaveFileModal isOpened={showDestinationPicker}
                modalTitle="Save to Dashboard"
                saveButtonLabel="Save"
                defaultName={getFileNameWithoutExtension(selectedExampleFile.name)}
                fileExtension={selectedFileExtension}
                fileExtensionText={`Simulation file (*.${selectedFileExtension})`}
                onSuccess={(data) => { setShowDestinationPicker(false); onCopyClick(data); }}
                onHide={() => { setSelectedExampleFile(undefined); setShowDestinationPicker(false); }}
            />}
    </div>
}