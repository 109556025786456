import React from "react";
import {
  CreateUserPostModel,
  ICreateUserPostModel,
} from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Label,
  Field,
  Input,
  Checkbox,
  dividerClassNames,
  Spinner,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Title3,
  Subtitle1,
  Subtitle2,
} from "@fluentui/react-components";
import {
  CheckmarkRegular,
  CheckRegular,
  ChevronRightRegular,
  CopyRegular,
  Dismiss24Filled,
  MailRegular,
  PasswordRegular,
  PeopleRegular,
  PersonRegular,
  WarningRegular,
} from "@fluentui/react-icons";
import { GLOBAL_STYLES } from "../../styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "./register-modal.scss";
import { processServerError } from "../../utils/helpers/error.helper";
import { getUsersClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { createLoginRequest } from "../../auth/authConfig";
import { S365Button } from "../../components/s365-button/s365-button.component";
import { getCurrentWebUrl } from "../../App";

type RegisterModalProps = {
  isOpened?: boolean;
  onClose: () => void;
};

type RegisterUserModel = Omit<
  ICreateUserPostModel,
  "accountConfirmationPage"
> & {
  privacyPolicyConsent: boolean;
  hCaptchaVerified: boolean;
};

const formSchema = Yup.object().shape<
  Record<keyof RegisterUserModel, Yup.AnySchema>
>({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Email is not in correct format."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long.") // Minimum length
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.") // At least one lowercase letter
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.") // At least one uppercase letter
    .matches(/\d/, "Password must contain at least one number.") // At least one number
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character."
    ),
  privacyPolicyConsent: Yup.boolean()
    .required("Privacy policy consent is required..")
    .oneOf([true], "Privacy policy consent is required."),
  hCaptchaVerified: Yup.boolean()
    .required("Captcha is required.")
    .oneOf([true], "Captcha is required."),
});

export const RegisterModal: React.FC<RegisterModalProps> = (props) => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [userPrincipalName, setUserPrincipalName] = React.useState<string>();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isSubmitted },
    reset,
  } = useForm<RegisterUserModel>({ resolver: yupResolver(formSchema) as any });
  React.useEffect(() => {
    setOpen(!!props.isOpened);
  }, [props.isOpened]);
  const onModalClose = () => {
    setOpen(false);
    reset();
    if (props.onClose) {
      props.onClose();
    }
  };

  const onSubmit = async (data: RegisterUserModel) => {
    try {
      setIsLoading(true);
      console.log("onSubmit", data);
      const client = getUsersClient();
      const model = new CreateUserPostModel({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
      });
      const createUserResp = await client.createUser(model);
      setUserPrincipalName(createUserResp.userPrincipalName!);
    } catch (error) {
      processServerError(
        error,
        setError,
        "An error occurred while registering user."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onLoginClick = () => {
    instance.loginRedirect(createLoginRequest(getCurrentWebUrl()));
  };

  const onCopyUsernameClick = (ev) => {
    ev.preventDefault();
    navigator.clipboard.writeText(userPrincipalName);
    toast.success(`Username copied to clipboard.`);
  }

  return (
    <Dialog
      open={open}
      modalType="alert"
      inertTrapFocus
      onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
          onModalClose();
        }
      }}
    >
      <DialogSurface style={{ maxWidth: "650px" }}>
        <form className="register-form-modal" noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogBody style={{ maxWidth: "inherit" }}>
            <DialogTitle
              action={
                <Button
                  disabled={isLoading}
                  appearance="transparent"
                  onClick={onModalClose}
                  icon={<Dismiss24Filled />}
                />
              }
            />
            {!isLoading && !userPrincipalName && (<DialogContent
              className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
              style={{ paddingLeft: "100px", paddingRight: "100px" }}
            >

              <h2 style={{ marginTop: 0, marginBottom: "var(--spacingVerticalXXXL)", textAlign: 'center' }}>Sign up to Simulate 365</h2>  
              <div className="input-form">
                <div style={{ display: "flex", gap: "var(--spacingHorizontalL)", width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                    <Field
                      required
                      label="First name"
                      className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                      validationState={errors.firstName ? "error" : "none"}
                      validationMessage={
                        errors.firstName ? errors.firstName.message : undefined
                      }
                    >
                      <Input
                        id="firstName"
                        className="input-field"
                        {...register("firstName")}
                      //   contentBefore={<PersonRegular />}
                      />
                    </Field>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                    <Field
                      label="Last name"
                      required
                      className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                      validationState={errors.lastName ? "error" : "none"}
                      validationMessage={
                        errors.lastName ? errors.lastName.message : undefined
                      }
                    >
                      <Input
                        id="lastName"
                        className="input-field"
                        {...register("lastName")}
                      //  contentBefore={<PersonRegular />}
                      />
                    </Field>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                  <Field
                    label="Business Email"
                    required
                    className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                    validationState={errors.email ? "error" : "none"}
                    validationMessage={
                      errors.email ? errors.email.message : undefined
                    }
                  >
                    <Input
                      id="email"
                      type="email"
                      className="input-field"
                      {...register("email")}
                    //   contentBefore={<MailRegular />}
                    />
                  </Field>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: "var(--spacingVerticalL)" }}>
                  <Field
                    label="Password"
                    required
                    className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                    validationState={errors.password ? "error" : "none"}
                    validationMessage={
                      errors.password ? errors.password.message : undefined
                    }
                    hint={"Minimum length is 8 characters with at least one uppercase letter, lowercase letter, number and special character."}
                  >
                    <Input
                      id="password"
                      type="password"
                      className="input-field"
                      {...register("password")}
                    // contentBefore={<PasswordRegular />}                      
                    />
                  </Field>
                </div>

                <div style={{ marginBottom: "var(--spacingVerticalXL)" }}>
                  <Field
                    validationState={
                      errors.privacyPolicyConsent ? "error" : "none"
                    }
                    validationMessage={
                      errors.privacyPolicyConsent
                        ? errors.privacyPolicyConsent.message
                        : undefined
                    }
                  >
                    <Checkbox
                      {...register("privacyPolicyConsent")}
                      label={
                        <small style={{ display: "block", lineHeight: "var(--lineHeightBase200)" }}>

                          By signing up, you agree to the CGC Capital-Gain Consultants  <a
                            href="https://simulate365.com/privacy-policy/"
                            target="_blank"
                          >
                            Privacy Policy
                          </a> and   <a
                            href="https://simulate365.com/wp-content/uploads/edd/shop-deployed/CGC-Cloud-EULA.pdf"
                            target="_blank"
                          >
                            Cloud End-User License Agreement (EULA)
                          </a>. You agree that we collect your details and that we may use the information provided to contact you about our products and events.
                          You may opt out from our communication at any time.

                        </small>
                      }
                    />
                  </Field>
                </div>

                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <Field
                    validationState={
                      errors.hCaptchaVerified ? "error" : "none"
                    }
                    validationMessage={
                      errors.hCaptchaVerified
                        ? errors.hCaptchaVerified.message
                        : undefined
                    }
                  >
                    <HCaptcha
                      sitekey="ca28c15d-d3b1-4075-97c8-7dbcfea0262e"
                      onVerify={(token) => {
                        // console.log("onVerify hcaptcha", token);
                        if (!!token)
                          setValue("hCaptchaVerified", true, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });
                      }}
                    />
                  </Field>
                </div>
              </div>




              {!isLoading && !userPrincipalName && <div style={{ display: "flex", justifyContent: "center" }}>
                <S365Button appearance="primary" type="submit" size="large" style={{ width: "300px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ paddingBottom: "3px", paddingRight: "var(--spacingHorizontalS)" }}>Continue</span>
                    <ChevronRightRegular />
                  </div>

                </S365Button>
              </div>
              }
            </DialogContent>)}

            {!isLoading && !!userPrincipalName &&
              (<DialogContent
                className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                style={{ paddingLeft: "100px", paddingRight: "100px", paddingTop: 0 }}
              >

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ marginTop: 0, marginBottom: "var(--spacingVerticalXXXL)" }}>Thank you for signing up!</h2>
                  <span style={{ marginBottom: "var(--spacingVerticalXL)" }}>
                    This is your Simulate 365 username.<br />
                    <b>Sign in with your username</b>.
                  </span>
                  <Input
                    size="large"
                    style={{ width: "100%", marginBottom: "var(--spacingVerticalXL)" }}
                    input={{ style: { textAlign: "center", fontWeight: "bold" } }}
                    value={userPrincipalName} readOnly
                    contentAfter={<Button appearance="transparent" icon={<CopyRegular onClick={onCopyUsernameClick} />}></Button>}
                  />

                  <S365Button
                    size="large"
                    appearance="secondary"
                    style={{ width: "fit-content" }}
                    onClick={onLoginClick}
                  >
                    Continue to sign in
                  </S365Button>
                  <MessageBar icon={<WarningRegular />} intent="warning" layout="multiline" style={{ marginTop: "20px", alignItems: "center", width: "100%" }}>
                    <MessageBarBody>
                      <span>We sent to your email an account confirmation link.<br />
                        Click on the confirmation link in the next 24 hours <br /> to keep your Simulate 365 account active.</span>
                    </MessageBarBody>
                  </MessageBar>
                </div>
              </DialogContent>)}

            {isLoading && <DialogContent
              className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
              style={{ paddingLeft: "100px", paddingRight: "100px", paddingTop: 0 }}>
              <div>
                {!userPrincipalName && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Spinner label={"Creating your account, please wait..."} />
                </div>}
              </div>
            </DialogContent>}

          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};
