import { Button, ButtonProps, makeStyles } from "@fluentui/react-components";
import React from "react";

type S365ButtonProps = ButtonProps & {

}

const useStyles = makeStyles({
    customButton: {
      backgroundColor: '#3EB489 !important', // Base color
      color: 'white !important',
      ':hover': {
        backgroundColor: '#369B7B !important', // Darker shade on hover
      },
      ':active': {
        backgroundColor: '#2C8F6E !important', // Even darker shade when pressed
      },
    },
  });

export const S365Button: React.FC<S365ButtonProps> = (props) => {
    const styles = useStyles();
    return <Button  {...props} className={styles.customButton} style={{backgroundColor: "#3EB489", color: "white", ...props.style }}>{props.children}</Button>
}