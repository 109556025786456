import React from "react";
import { GLOBAL_STYLES } from "../../styles";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Spinner, Input, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { CopyRegular, Dismiss24Filled, ErrorCircleRegular } from "@fluentui/react-icons";
import sidebarLogo from "../../assets/sidebar-logo.png";
import { useLoading } from "../../utils/loading-indicator.component";
import { getUsersClient } from "../../services/dashboard.service";
import { getErrorMessagesFromResponse } from "../../utils/helpers/error.helper";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { S365Button } from "../../components/s365-button/s365-button.component";
import { useNavigate } from "react-router-dom";
import { createLoginRequest } from "../../auth/authConfig";
import { getCurrentWebUrl } from "../../App";

type EmailConfirmationDialogProps = {
    confirmationToken: string;
    isOpened?: boolean;
    onClose: () => void;
}

export const EmailConfirmationDialog: React.FC<EmailConfirmationDialogProps> = (props) => {

    const [userPrincipalName, setUserPrincipalName] = React.useState<string>();
    const [errorMessages, setErrorMessages] = React.useState<string[]>();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { instance } = useMsal();
    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();
    React.useEffect(() => {
        if (!!props.confirmationToken) {
            confirmAccount(props.confirmationToken);
        }

    }, [props.confirmationToken]);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);
    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const onLoginClick = () => {
        instance.loginRedirect(createLoginRequest(getCurrentWebUrl()));
    };

    const onContinueClick = () => {
        navigate("/");
        props.onClose();
    }

    const confirmAccount = async (token: string) => {
        try {
            setIsLoading(true);
            const client = getUsersClient();
            const confirmResp = await client.confirmRegistration(token);
            setUserPrincipalName(confirmResp.userPrincipalName);
        } catch (error) {
            console.log("An error occurred while confirming account.", error);
            const errors = getErrorMessagesFromResponse(
                error,
                undefined,
                "An error occurred while confirming account."
            );
            setErrorMessages(errors);
        } finally {
            setIsLoading(false);
        }

    }
    const onCopyUsernameClick = (ev) => {
        ev.preventDefault();
        navigator.clipboard.writeText(userPrincipalName);
        toast.success(`Username copied to clipboard.`);
    }

    return <Dialog
        open={open}
        modalType="alert"
        inertTrapFocus
        onOpenChange={(event, data) => {
            setOpen(data.open);
            if (!data.open) {
                onModalClose();
            }
        }}
    >
        <DialogSurface style={{ maxWidth: "650px" }}>

            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle>

                </DialogTitle>
                <DialogContent
                    className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}
                //  style={{ paddingLeft: "100px", paddingRight: "100px" }}
                >

                    {!isLoading &&
                        !!errorMessages &&
                        errorMessages.length > 0 && (
                            <MessageBar icon={<ErrorCircleRegular />} intent="error" layout="multiline"
                                style={{ marginBottom: "20px", alignItems: "center", width: "100%" }}>
                                <MessageBarBody>
                                    {errorMessages.map((message) => {
                                        return <span>
                                            {message}
                                        </span>;
                                    })}
                                </MessageBarBody>
                            </MessageBar>
                        )}

                    {!isLoading && !!userPrincipalName && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                alignItems: "center",
                            }}
                        >
                            <h2 style={{ marginTop: 0, marginBottom: "var(--spacingVerticalXXXL)" }}>Thank you for confirming your email address!</h2>

                            {!isAuthenticated && <>
                                <span style={{ marginBottom: "var(--spacingVerticalXL)" }}>
                                    Your account will remain active.<br />
                                    This is your Simulate 365 username.<br />
                                    <b>Sign in with your username</b>.
                                </span>
                                <Input
                                    size="large"
                                    style={{ maxWidth: "400px", width: "100%", marginBottom: "var(--spacingVerticalXL)" }}
                                    input={{ style: { textAlign: "center", fontWeight: "bold" } }}
                                    value={userPrincipalName} readOnly
                                    contentAfter={<Button appearance="transparent" icon={<CopyRegular onClick={onCopyUsernameClick} />}></Button>}
                                />

                                <S365Button
                                    size="large"
                                    appearance="secondary"
                                    style={{ width: "fit-content" }}
                                    onClick={onLoginClick}
                                >
                                    Continue to sign in
                                </S365Button>

                            </>}
                            {isAuthenticated && <>
                                <span style={{ marginBottom: "var(--spacingVerticalXL)" }}>
                                    Your account will remain active.
                                </span>
                                <S365Button
                                    size="large"
                                    appearance="secondary"
                                    style={{ width: "fit-content" }}
                                    onClick={onContinueClick}
                                >
                                    Continue to Dashboard
                                </S365Button>

                            </>}
                        </div>
                    )}
                    {isLoading && (
                        <div style={{ margin: "25px 10px" }}>
                            <Spinner size="medium" label="Confirming account..." />
                        </div>
                    )}

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}