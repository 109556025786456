import axios from "axios";
import { msalInstance } from "../index";
import { FilesClient, FiltersClient, GroupsClient, TagsClient, ThumbnailsClient, SharingClient } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { settings } from "../App";
import { FlowsheetsClient } from "../swagger-clients/dispatcher-next-api-clients.service";
import { AxiosInstance } from "./dashboard.service";
import { EducationsClient, ExamActionsClient, ExamCountClient, ExamDataClient, ExamsClient } from "../swagger-clients/s365-take-home-exams-v2-clients.service";




export const getExamsClient = () => {
    return new ExamsClient(settings?.takeHomeExamsServiceUrl, AxiosInstance);
}

export const getExamDataClient = () => {
    return new ExamDataClient(settings?.takeHomeExamsServiceUrl, AxiosInstance);
}

export const getExamActionsClient = () => {
    return new ExamActionsClient(settings?.takeHomeExamsServiceUrl, AxiosInstance);
}

export const getEducationsClient = () => {
    return new EducationsClient(settings?.takeHomeExamsServiceUrl, AxiosInstance);
}

export const getExamCountClient = () => {
    return new ExamCountClient(settings?.takeHomeExamsServiceUrl, AxiosInstance);
}






