import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { stripeSettings } from "../../App";
import { useMsal } from "@azure/msal-react";


type SubscribeDialogProps = {
    isOpened?: boolean;
    onClose?: () => void;
}

export const SubscribeDialog: React.FC<SubscribeDialogProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [stripeTableId, setStripeTableId] = React.useState<string>();
    const [stripeKey, setStripeKey] = React.useState<string>();
    const { instance } = useMsal();

    const account = instance.getActiveAccount();
    React.useEffect(() => {
        console.log("stripeSettings", stripeSettings);
        setStripeTableId(stripeSettings?.subscribePricingTableId);
        setStripeKey(stripeSettings?.subscribePricingTableKey);

    }, []);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }
    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "800px", backgroundColor: "#046795", color: "white" }}>

            <DialogBody>
                <DialogTitle action={<Button style={{ color: "white" }} appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}>
                    Pricing
                </DialogTitle>
                <DialogContent>
                    <div style={{ backgroundColor: "#046795", height: "500px" }}>
                        {!!stripeKey && !!stripeTableId && account.localAccountId && <div dangerouslySetInnerHTML={{
                            __html: `
                   <stripe-pricing-table pricing-table-id="${stripeTableId}"
                        publishable-key="${stripeKey}" client-reference-id="${account.localAccountId}">
                    </stripe-pricing-table>`}}>

                        </div>}
                    </div>


                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}