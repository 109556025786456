import React from "react";
import { HideSidebarSectionInTour, ShowSidebarSectionInTour } from "./tour-guide.shared";

type ClusterTourProps = {

}

export const ClusterTour: React.FC<ClusterTourProps> = (props) => {
    React.useEffect(() => {

        ShowSidebarSectionInTour("cluster_sidebar_link");

        return () => {
            // Close my work menu section
            HideSidebarSectionInTour("cluster_sidebar_link");
        }
    }, [])
    return <div style={{   marginTop: "var(--spacingVerticalMNudge)",   marginBottom: "var(--spacingVerticalMNudge)" }}>
        <p>Keep an overview of your Agent activities and all flowsheet calculations.</p>
        <p>Design tools (MSS, DoE, OT) on Dashboard run calculations on simulation software instances. 
            We call the instances Agents. 
            Using several Agents in parallel, you can form a Cluster to reduce overall calculation time.</p>
            <p> Here, you can overview your Agents activities and tasks.</p>
        <span style={{ fontWeight: "bold" }}>Power tip!</span>
        <br />
        <span>Your Dashboard has 2 DWSIM Pro instances deployed for you to test. Use our example flowsheet to see how Agents analyze a flowsheet.</span>
       <br />
        <span>View results on Dashboard without accessing the simulator. Check out flowsheet details on right-click and view your flowsheet properties on the thumbnail. </span>
    </div>
}