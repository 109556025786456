import { Label, Switch, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Save20Regular } from "@fluentui/react-icons";

import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../../components/breadcrumbs/breadcrumbs";
import CountdownTimer from "../../../components/countdown-timer/countdown-timer.component";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";
import { getExamsClient } from "../../../services/take-home-exams.service";
import { ErrorResponseModel } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { ExamStatus, SubmittedExamParameterPostModel, SubmittedExamPostModel } from "../../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { addHours, addMinutes } from "../../../utils/helpers/date.helpers";
import { LoadingIndicator, useLoading } from "../../../utils/loading-indicator.component";
import { getExamStatus, mapExamPostModelToIExam } from "../../shared/exam.utilities";
import { IExam, IExercise } from "../../shared/exams.models";
import { validate } from "./submit-results.validation";
import "./submit-results.styless.scss";
import { ResultsTable } from "./results-table/results-table.component";
import { settings } from "../../../App";
import { InformationDialog } from "../../../components/information-dialog/information-dialog.component";
import moment from "moment";
type SubmitResultsProps = {

}
export type SubmitResultsRouteParams = {
    examId: string;
}

export const SubmitResults: React.FC<SubmitResultsProps> = (props) => {

    const [exam, setExam] = React.useState<IExam>();
    const [isLoading, loadingService] = useLoading();
    const [autoSave, setAutoSave] = React.useState<boolean>(true);
    const [showExamFinishedModal, setShowExamFinishedModal] = React.useState<boolean>(false);
    const routeParams = useParams<SubmitResultsRouteParams>();
    const examFinishedCheckIntervalRef = useRef<number>();
    // this value is needed because when setInterval is called exam is null and it will never update    
    const examRef = useRef<IExam>();
    const navigate = useNavigate();
    useEffect(() => {
        getExam();
        examFinishedCheckIntervalRef.current = window.setInterval(() => {
            examFinishedIntervalCheck();
        }, 15000);

        // This function will be called when the component is unmounted
        return () => {
            console.log("Clear interval called");
            clearInterval(examFinishedCheckIntervalRef.current);
        };
    }, []);

    React.useEffect(() => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = `${settings.takeHomeExamsServiceUrl}/Views/print-styles.css`
        document.querySelector("head").appendChild(link);
    }, []);

    const examFinishedIntervalCheck = () => {
        console.log("examFinishedIntervalCheck called", exam, examRef);
        if (examRef?.current) {

            if (examRef.current.status == ExamStatus.Started) {
                checkIfExamFinished().then((status: ExamStatus) => {
                    if (status && status == ExamStatus.Finished) {

                        if (!showExamFinishedModal) {
                            setShowExamFinishedModal(true);
                        }
                    }
                }).catch();

            } else {
                clearInterval(examFinishedCheckIntervalRef.current);
            }
        }
    }

    const checkIfExamFinished = async (): Promise<ExamStatus> => {
        try {
            console.log("Checking if exam has finished.");
            const client = getExamsClient();
            const examStatus = await client.getExamStatus(+routeParams.examId);
            return examStatus;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking exam status.");
        }
    }


    const getExam = async () => {
        const messageId = loadingService.showMessage("Getting exam...");
        try {
            const client = getExamsClient();
            const exam = await client.getStudentExam(+routeParams.examId);
            const mappedExam = mapExamPostModelToIExam(exam);
            setExam(mappedExam);
            examRef.current = mappedExam;

            await getSubmittedResults(mappedExam);


        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get exam.");

        }
        finally {
            loadingService.hideMessage(messageId);
        }
    }

    const getSubmittedResults = async (exam: IExam) => {
        const messageId = loadingService.showMessage("Checking for previous submissions...");
        try {
            const examId = +routeParams.examId;
            const client = getExamsClient();
            const submittedResult = await client.getSubmittedAnswers(examId);
            if (submittedResult) {
                let updatedExam = { ...exam };
                updatedExam.exercises.forEach(exercise => {

                    if (!exercise.subExercises || exercise.subExercises.length == 0) {
                        setPrameterValuesFromResult(exercise, submittedResult);
                    } else {
                        exercise.subExercises.forEach(subExercise => {
                            setPrameterValuesFromResult(subExercise, submittedResult);
                        });
                    }

                });

                setExam(updatedExam);
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking for previous submission.");
        } finally {
            loadingService.hideMessage(messageId);
        }

    }
    const setPrameterValuesFromResult = (exercise: IExercise, submittedResult: SubmittedExamPostModel) => {
        exercise.outputParameters.forEach(outParam => {
            let existingParam = submittedResult.results.find(x => x.exerciseId == exercise.id && x.parameterId == outParam.id);
            if (existingParam) {
                outParam.value = existingParam.value;
            }
        });
    }

    const getParamsFromExercise = (exercise: IExercise, parameters: SubmittedExamParameterPostModel[]) => {
        exercise.outputParameters.forEach(outparam => {
            const param = {
                parameterId: outparam.id,
                exerciseId: outparam.exerciseId,
                value: outparam.value
            } as SubmittedExamParameterPostModel;
            parameters.push(param);
        });
    }

    const onSave = async () => {
        loadingService.showLoading("Saving results ...", async (hideMessage) => {
            try {

                let validationResult = validate(exam);
                if (validationResult.isInvalid()) {
                    let globalErrors = validationResult.getGlobalErrors();
                    if (globalErrors && globalErrors.length > 0) {
                        toast.error(globalErrors.join("\n"));
                    }
                } else {

                    const client = getExamsClient();
                    var paramters = [] as SubmittedExamParameterPostModel[];
                    exam.exercises.forEach(exercise => {
                        if ((!exercise.subExercises) || (exercise.subExercises.length == 0)) {
                            getParamsFromExercise(exercise, paramters);
                        } else {
                            exercise.subExercises.forEach(subExercise => {
                                getParamsFromExercise(subExercise, paramters);
                            });
                        }

                    });
                    var model = {
                        examId: exam.id,
                        results: paramters
                    } as SubmittedExamPostModel;

                    await client.submitResults(model);
                    toast.success("Results successfully submitted.");
                }
            } catch (error) {

                processServerError(error, undefined, "An error occurred while submitting results.");

            } finally {
                hideMessage();
            }
        });



    }

    let validationResult = validate(exam);
    const durationSplit = exam ? exam.duration.split(":") : [];
    const startedAt: Date = exam ? moment.utc(moment(exam.startedAt).format("YYYY-MM-DD HH:mm:ss")).local().toDate() : undefined;
    const expiryDate = durationSplit.length > 0 ? (addHours(addMinutes(startedAt, +durationSplit[1]), +durationSplit[0])) : undefined;


    return <div className="content-wrapper">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                    onClick={() => { onSave(); }}
                    icon={<Save20Regular />}>Save</ToolbarButton>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                ><div className="auto-save-button">
                        <Switch
                            checked={autoSave}
                            disabled={isLoading}
                            onChange={(ev, data) => { setAutoSave(data.checked) }}
                        />
                    </div> Auto save</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                <Breadcrumb
                    key={`breadcrumb-dashboard`}
                    onClick={() => navigate(`/files`)}
                >My Work</Breadcrumb>
                <Breadcrumb key={`breadcrumb-exams`} onClick={() => navigate(`/student-exams`)} >Exams</Breadcrumb>
                {exam && <Breadcrumb key={`breadcrumb-current-exam`} active={true}>{exam.name}</Breadcrumb>}


            </Breadcrumbs>
        </div>

        <div className="input-form">
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Exam name:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam?.name}</p>
            </div>

            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Start time:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? exam.startTime : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Duration:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? exam.duration : ""} (HH:mm)</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Status:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? getExamStatus(exam.status) : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Started at:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? <ShowLocalTime date={exam.startedAt} /> : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Time left:</Label>

                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && exam.status == ExamStatus.Started ? <CountdownTimer expiryDate={expiryDate} /> : "-"}</p>
            </div>


        </div>

        <ResultsTable
            validationResult={validationResult}
            exercises={exam ? exam.exercises : []}
            isFormSubmitted={true}
            isRequestInProgress={isLoading}
            loadingService={loadingService}
            isDisabled={exam && exam.status == ExamStatus.Started ? false : true}
            onAutoSave={() => {
                if (autoSave == true) {
                    onSave();
                }
            }}
            onChange={(exercises) => {

                setExam(exam => ({ ...exam, exercises: exercises }))
            }}
        />

        {showExamFinishedModal &&
            <InformationDialog
                isOpened={true}
                title="Exam finished"
                subText="Exam has finished. We hope that you saved your result in time. Please refresh this page."
                onClose={() => { setShowExamFinishedModal(false); getExam(); }}
            />}



    </div>
}
